import digitalMarketingImg from "./images/Digital-Marketing.jpg";
import pmarketingImg from "./images/pMarrketing.png";
import cAnalysisImg from "./images/cAnalysis.png";
import smMarketing from "./images/smMarketing.png";
import freshIdeas from "./images/freshIdeas.jpg";
import freshIdeas1 from "./images/freshIdeas1.png";
import Footer from "./Footer";

const About = () => {
  return (
    <>
      <section id="aboutus">
        <div className="row text-center mt-3">
          <div className="col-md-12 text-center" data-aos="fade-up">
            <h2 className="main-color fw-bold text-decoration-underline">
              About Us
            </h2>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 header-img" data-aos="fade-right">
            <img
              className="image-fuild animated"
              src={digitalMarketingImg}
              alt="aboutUs"
              style={{ width: "100%" }}
              loading="lazy"
            />
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <h4 className="text-secondary mt-3">
              <span className="main-color fs-1 fw-bold">
                AZ Digital Marketing{" "}
              </span>
              is a result driven performance based digital marketing company. We
              are backed with a team of highly dedicated, motivated and
              experienced professional, for which ZettaMobi has earned a
              reputation of delivering quality. Our mission: to generate high
              quality traffic for our advertisers while building strong long
              term relationships with our publishers.
            </h4>
          </div>
        </div>

        <div className="row g-3 mt-5">
          <div className="col-md-4">
            <div className="card h-100 shadow-sm bg-warning" data-aos="flip-left">
              <img src={pmarketingImg} className="card-img-top" alt="..." loading="lazy" />
              <div className="card-body">
                <h5 className="card-title text-center">
                  Performance Marketing
                </h5>
                <div className="text-center my-2">
                  Performance marketing is like getting a prize for ads that
                  work well. You pay only when people take action.
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4" data-aos="flip-right">
            <div className="card h-100 shadow-sm bg-warning">
              <img src={cAnalysisImg} className="card-img-top" alt="..." loading="lazy" />
              <div className="card-body">
                <h5 className="card-title text-center">Competitor Analysis</h5>
                <div className="text-center my-2">
                  Competitor Analysis is like watching other players in a game.
                  It helps us learn their moves to make our strategy better
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4" data-aos="flip-right">
            <div className="card h-100 shadow-sm bg-warning">
              <img src={smMarketing} className="card-img-top" alt="..." loading="lazy" />
              <div className="card-body">
                <h5 className="card-title text-center">
                  Social Media Marketing
                </h5>
                <div className="text-center my-2">
                  Social Media Marketing is like telling cool stories about your
                  stuff on Facebook, Instagram, and others. It makes friends
                  interested!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <h1 className="fw-bold text-center mb-4" data-aos="fade-up">
            Fresh Ideas for Every Business
          </h1>
          <div className="col-md-6">
            {/* <img className="image-fuild animated" src={freshIdeas} alt="fresh Ideas" /> */}
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6" data-aos="flip-left">
            <img
              className="image-fuild animated w-100 mb-3"
              src={freshIdeas1}
              alt="fresh Ideas1"
              loading="lazy"
            />
          </div>
          <div className="col-md-6">
            <div className="p-3 mt-2 mb-2 bg-success card" data-aos="fade-right">
              <h2 className="fw-bold fs-5">Website Marketing</h2>
            </div>

            <div className="p-3 mt-2 mb-2  bg-primary card" data-aos="fade-left">
              <h2 className="fw-bold fs-5">Pay-Per-Click Advertising</h2>
            </div>

            <div className="p-3 mt-2 mb-2 bg-info card" data-aos="fade-right">
              <h2 className="fw-bold fs-5">Content Marketing</h2>
            </div>

            <div className="p-3 mt-2 mb-2  bg-warning card" data-aos="fade-left">
              <h2 className="fw-bold fs-5">Email Marketing</h2>
            </div>

            <div className="p-3 mb-2 colored card mb-3" data-aos="fade-down">
              <h2 className="fw-bold fs-5">Social Media Marketing</h2>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default About;
