import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Menu from "./Menu";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  
  return (
    <>
      <div className="container-fluid nv_bg">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg navbar-light bg-light fw-bold">
              <div className="container-fluid">
                <NavLink
                  className="navbar-brand main-color fs-4 fw-bold text-decoration-underline"
                  to="/"
                >
                  EZ Digital Service
                </NavLink>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleDrawer}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to="/"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle"
                        to="/services"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Services
                      </NavLink>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/email-marketing"
                          >
                            Email Service
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/sms-marketing"
                          >
                            SMS Service
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/about">
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                  <form className="d-flex">
                    <NavLink to="/contact">
                      <button
                        className="btn btn-outline-warning text-secondary"
                        type="submit"
                      >
                        CREATE YOUR BRAND WITH US
                      </button>
                    </NavLink>
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </div>

        {/* MODEL */}
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="bla bla bla"
        >
          <div
            className="bg-warning d-flex justify-content-center align-items-center"
            style={{ height: "10%" }}
          >
            <h1 className="main-color fw-bold text-decoration-underline text-center">
              EZ Digital
            </h1>
          </div>
          <div className="p-4">
            <Menu onLinkClick={() => setIsOpen(false)} />
          </div>
        </Drawer>
        {/* END - MODEL */}
      </div>
    </>
  );
};

export default Navbar;
