import { TypeAnimation } from "react-type-animation";
import SocialMediaImg from "./images/social-media-img1.png";
import circleOrange from "./images/circle_orange.png";
import circleBlue from "./images/circle_blue.png";
import circleGreen from "./images/circle_green.png";
import circlePurple from "./images/circle_purple.png";
import webTraficImg from "./images/web-trafic.png";
import homeCompImg from "./images/home-comp.png";
import programingAdv from "./images/pa.png";
import emailMarketing from "./images/em.png";
import phoneMarketing from "./images/phone.png";
import faceBook from "./images/facebook.png";
import handImg from "./images/hand.png";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <section id="home">
        <div className="row">
          <div className="col-md-5 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column" data-aos="fade-right">
            <h1 className="fs-1 fw-bold main-color text-decoration-underline">
              Digital Marketing
            </h1>
            <TypeAnimation
              className="main-color fw-bold"
              sequence={[
                // Same substring at the start will only be typed out once, initially
                "Business",
                1000, // wait 1s before replacing
                "Services",
                1000,
                "Business",
                1000,
                "Services",
                1000,
              ]}
              wrapper="span"
              speed={30}
              style={{ fontSize: "50px", display: "inline-block" }}
              repeat={Infinity}
            />
            <h4 style={{ color: "#7a7a7a" }}>
              {" "}
              EZ Digital Marketing is a result driven performance based digital
              marketing company. We are backed with a team of highly dedicated,
              motivated and experienced professional, for which EZ Digital
              Marketing has earned a reputation of delivering quality.
            </h4>
          </div>
          <div className="col-md-7 order-1 order-lg-2 header-img  d-flex justify-content-center flex-column"  data-aos="fade-left">
            <img
              src={SocialMediaImg}
              className="image-fuild animated"
              alt="img-d1"
              loading="lazy"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column" data-aos="fade-down">
            <h1 className="fs-1 fw-bold text-black">
              Boost your Website Traffic!
            </h1>
            <h4 style={{ color: "#7a7a7a" }}>
              {" "}
              In today’s digital landscape, high website traffic isn’t just a
              vanity metric – it’s a key driver of success. Increased traffic
              means more eyes on your content, products, and services,
              translating to higher engagement, conversions, and revenue.
              Whether you’re looking to expand your audience, grow your customer
              base, or increase brand visibility, boosting website traffic is
              the path to achieving your goals.
            </h4>
          </div>
          <div className="col-md-7 order-1 order-lg-2 header-img  d-flex justify-content-center flex-column">
            <div className="row">
              <div className="col-md-6 p-3" data-aos="flip-up">
                <div className="card data-cards p-3">
                  <img
                    className="card-img-top"
                    src={circleOrange}
                    alt="Card"
                    style={{ width: "140px" }}
                    loading="lazy"
                  />
                  <div className="text-center">
                    <h4 className="card-title">Discover, Export the Product</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-3" data-aos="flip-up">
                <div className="card data-cards p-3">
                  <img
                    className="card-img-top"
                    src={circleBlue}
                    alt="Card"
                    style={{ width: "140px" }}
                    loading="lazy"
                  />
                  <div className="text-center">
                    <h4 className="card-title">
                      Art Direction and Brand Strategy
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-3" data-aos="flip-up">
                <div className="card data-cards p-3">
                  <img
                    className="card-img-top"
                    src={circlePurple}
                    alt="Card"
                    style={{ width: "140px" }}
                    loading="lazy"
                  />
                  <div className="text-center">
                    <h4 className="card-title">Web Design and Development</h4>
                  </div>
                </div>
              </div>

              <div className="col-md-6 p-3" data-aos="flip-up">
                <div className="card data-cards p-3">
                  <img
                    className="card-img-top"
                    src={circleGreen}
                    alt="Card"
                    style={{ width: "140px" }}
                    loading="lazy"
                  />
                  <div className="text-center">
                    <h4 className="card-title">
                      Marketing Strategy and Comaigns
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-5 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column" data-aos="zoom-in">
            <h1 className="fs-2 fw-bolder text-black">
              Pay for Qualified Traffic
            </h1>
            <h4 style={{ fontSize: "16px", color: "#7a7a7a" }}>
              {" "}
              We understand that investing in paid traffic is an important
              decision. That’s why we’re dedicated to ensuring your investment
              pays off. By paying for qualified traffic, you’re not just getting
              clicks – you’re getting the opportunity to build meaningful
              connections with potential customers.
            </h4>
            {/* Counters */}
            <div className="d-flex flex-row justify-content-between">
              <h6 className="fw-bold">Campaign Analysis</h6>
              <h6 className="mr-4">95%</h6>
            </div>
            <div className="progress-custom">
              <div className="progress-value-custom1"></div>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <h6 className="fw-bold">Campaign Audit</h6>
              <h6 className="mr-4">70%</h6>
            </div>
            <div className="progress-custom">
              <div className="progress-value-custom2"></div>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <h6 className="fw-bold">Campaign Optimization</h6>
              <h6 className="mr-4">70%</h6>
            </div>
            <div className="progress-custom">
              <div className="progress-value-custom3"></div>
            </div>

            <NavLink to="/contact">
              <button type="button" className="btn btn-warning mt-4">
                Contact Us
              </button>
            </NavLink>
          </div>
          <div className="col-md-7 order-1 order-lg-2 header-img  d-flex justify-content-center flex-column p-3" data-aos="zoom-in">
            <img
              src={webTraficImg}
              className="image-fuild animated"
              alt="img-d1"
              loading="lazy"
            />
          </div>
        </div>

        {/* counters */}
        <div className=" pt-5 pt-lg-0">
          <div className="row">
            <div className="four col-md-3" data-aos="zoom-out">
              <div className="counter-box colored card mt-3">
                <i className="fa fa-thumbs-o-up"></i>
                <span className="counter">40</span>
                <p>Partners</p>
              </div>
            </div>
            <div className="four col-md-3">
              <div className="counter-box card bg-warning mt-3" data-aos="zoom-out">
                <i className="fa fa-group"></i>
                <span className="counter">50</span>
                <p className="text-white">Offers</p>
              </div>
            </div>
            <div className="four col-md-3">
              <div className="counter-box card bg-info mt-3" data-aos="zoom-out">
                <i className="fa  fa-shopping-cart"></i>
                <span className="counter">10M+</span>
                <p className="text-white">Clicks</p>
              </div>
            </div>
            <div className="four col-md-3">
              <div className="counter-box card bg-success mt-3" data-aos="zoom-out">
                <i className="fa  fa-user"></i>
                <span className="counter">1K+</span>
                <p className="text-white">Leads</p>
              </div>
            </div>
          </div>
        </div>

        {/* cards What we offer */}

        <section className=" pt-5 pt-lg-0 mt-3">
          <div>
            <h1 className="text-center fw-bold">What We Offer</h1>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 p-4 " data-aos="flip-right">
              <div className="our-team card border-success">
                <div className="row text-center">
                  <div className="col-md-12">
                    <div className=" pic">
                      <img src={homeCompImg} alt="homeImg" loading="lazy"/>
                    </div>
                  </div>
                </div>
                <h3 className="title">Performance Marketing</h3>
                <span className="post">
                  It offers precise targeting, real-time tracking, and the
                  ability to adjust campaigns based on performance data. This
                  data-driven approach helps optimize marketing efforts for
                  maximum ROI.
                </span>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 p-4" data-aos="flip-right">
              <div className="our-team card border-success"  >
                <div className="row text-center">
                  <div className="col-md-12">
                    <div className=" pic">
                      <img src={programingAdv} alt="programmingImg" loading="lazy" />
                    </div>
                  </div>
                </div>

                <h3 className="title">Programmatic Advertising</h3>
                <span className="post">
                  Programmatic advertising increases efficiency by reducing
                  manual tasks, improves ad targeting, and allows advertisers to
                  reach users at the right time and on the right platforms. It
                  also provides detailed data for campaign optimization.
                </span>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 p-4"  data-aos="flip-right">
              <div className="our-team card border-success">
                <div className="row text-center">
                  <div className="col-md-12">
                    <div className=" pic">
                      <img src={emailMarketing} alt="emailMarketing" loading="lazy" />
                    </div>
                  </div>
                </div>
                <h3 className="title mt-2">Email Marketing</h3>
                <span className="post mt-2">
                  Email marketing is like sending fun notes to friends, but for
                  your business. We use it to tell you about cool stuff, like
                  new products or special deals. It's a way to keep in touch and
                  share exciting news with you. Just like how your friend sends
                  you updates.
                </span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 p-4"  data-aos="flip-right">
              <div className="our-team card border-success">
                <div className="row text-center">
                  <div className="col-md-12">
                    <div className="pic">
                      <img
                        className="image-fuild"
                        style={{ width: "60%" }}
                        src={phoneMarketing}
                        alt="phone Marketing"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <h3 className="title">SMS Marketing</h3>
                <span className="post">
                  SMS marketing boasts high open and engagement rates since most
                  people read their text messages quickly. It's effective for
                  delivering urgent messages and personalized offers.
                  Additionally, it has a broad reach since most people have
                  mobile phones.
                </span>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 p-4" data-aos="flip-right">
              <div className="our-team card border-success">
                <div className="row text-center">
                  <div className="col-md-12">
                    <div className=" pic">
                      <img src={faceBook} alt="facebook" loading="lazy" />
                    </div>
                  </div>
                </div>
                <h3 className="title">Social Media Marketing</h3>
                <span className="post">
                  Social media marketing is how we share fun stories and
                  pictures about your business on sites like Facebook and
                  Instagram. It's like telling everyone in town about your cool
                  stuff, so they want to come and check it out. We make sure
                  your business shines like a bright star online.
                </span>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 p-4" data-aos="flip-right">
              <div className="our-team card">
                <div className="row text-center">
                  <div className="col-md-12">
                    <div className=" pic">
                      <img src={handImg} alt="Hand" loading="lazy" />
                    </div>
                  </div>
                </div>
                <h3 className="title">Pay Per Click (PPC)</h3>
                <span className="post">
                  Pay per click is a super cool way to show your ads to people
                  online. You only pay when someone clicks on your ad, like
                  giving a high-five for being interested. It's like a magic
                  coin – you use it, and your ad appears in front of lots of
                  eyes. Pay Per Click helps you get noticed.
                </span>
              </div>
            </div>
          </div>
        </section>
        {/* End Cards */}
        <Footer />
      </section>
    </>
  );
};

export default Home;
