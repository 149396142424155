import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const Layout = () => {
  return (
    <>
    <Navbar/>
    <div className="container-fluid nav_bg">
          <div className="row">
            <div className="col-11 mx-auto">
      <Outlet />
      </div>
      </div>
      </div>
    </>
  )
};
export default Layout;
