import { NavLink } from "react-router-dom"

const Menu = (props) => {
  return (
   <>
     <ul className="navbar-nav m-auto mb-2 mb-lg-0 fs-2">
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to="/"
                       onClick={props.onLinkClick}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link dropdown-toggle"
                        to="/services"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Services
                      </NavLink>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/email-marketing"
                            onClick={props.onLinkClick}
                          >
                            Email Service
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/sms-marketing"
                            onClick={props.onLinkClick}
                          >
                            SMS Service
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/about" onClick={props.onLinkClick}>
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact" onClick={props.onLinkClick}>
                        Contact
                      </NavLink>
                    </li>
                  </ul>
   </>
  )
}

export default Menu