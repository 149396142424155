import smsMarketing from "./images/smsMarketingD.png";
import Footer from "./Footer";

const SMSMarketing = () => {
  return (
    <section id="emailMarketing">
      <div className="row">
        <div className="col-md-12 text-center mt-3" data-aos="fade-up">
          <h2 className="main-color fw-bold text-decoration-underline">
            SMS Marketing
          </h2>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6" data-aos="fade-right">
          <img
            src={smsMarketing}
            className="image-fuild animated"
            alt="sms Marketing"
            style={{ width: "100%" }}
            loading="lazy"
          />
        </div>
        <div className="col-md-6" data-aos="fade-left">
          <div className="row">
            <div className="col-md-12">
              <h3 className="fw-bold">
                We Provide Best SMS Marketing Services
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h4 className="main-color fw-bold">01. Direct Communication</h4>
              <p className="mt-2">
                SMS marketing lets you talk directly to your customers through
                text messages on their phones.
              </p>
            </div>
            <div className="col-md-6">
              <h4 className="main-color fw-bold">02. High Open Rates</h4>
              <p className="mt-2">
                People usually read text messages quickly, so your message has a
                good chance of being seen.
              </p>
            </div>
            <div className="col-md-6">
              <h4 className="main-color fw-bold">03. Targeted Messaging</h4>
              <p className="mt-2">
                You can send specific messages to different groups of customers
                based on their interests or behaviors.
              </p>
            </div>
            <div className="col-md-6">
              <h4 className="main-color fw-bold">04. Engagement and Sales</h4>
              <p className="mt-2">
                SMS marketing can boost customer engagement and lead to more
                sales, especially when you send offers or promotions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
      <Footer />
      </div>
    </section>
  );
};

export default SMSMarketing;
