import locationImg from "./images/location.png";
import emailMarketing from "./images/em.png";
import phoneMarketing from "./images/phone.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="row text-white bg-dark p-2" data-aos="fade-right">
        <div className="col-md-3"></div>
        <div className="col-md-3">
          <img
            src={phoneMarketing}
            style={{ width: "22px", height: "25px" }}
            alt="phone marketing"
            loading="lazy"
          />
          <p className="text-wrap" style={{ color: "#FDE8B6" }}>
            +91 70088 36463
          </p>
        </div>
        <div className="col-md-3">
          <img
            src={emailMarketing}
            style={{ width: "22px", height: "25px", color: "#FDE8B6" }}
            alt="email marketing"
            loading="lazy"
          />
          <p className="text-wrap" style={{ color: "#FDE8B6" }}>
            admin@ezdigital.in
          </p>
        </div>
        <div className="col-md-3">
          <img
            src={locationImg}
            style={{ width: "22px", height: "25px" }}
            alt="location"
            loading="lazy"
          />
          <p className="text-wrap" style={{ width: "auto", color: "#FDE8B6" }}>
            Shop no.19, Ground floor, Radha Madhav Textile Market, Devadh,
            Surat, Gujarat, 394210
          </p>
        </div>
      </div>

      <div className="row text-white bg-dark p-2" data-aos="fade-up">
        <div className="col-md-3">
          <h2 className="main-color fs-bold">EZ Digital Marketing</h2>
          <p>
            <span className="text-secondary">
              EZ Digital Marketing is a result driven performance based digital
              marketing company.We are backed with a team of highly dedicated,
              motivated and experienced professional, for which EZ Digital
              Marketing has earned a reputation of delivering quality.
            </span>
          </p>
        </div>
        <div className="col-md-3">
          <h4 className="main-color fs-bold">Services</h4>
          <ul className="text-primary">
            <li>Programmatic Advertising</li>
            <li>Email Marketing</li>
            <li>SMS Marketing</li>
            <li>SMM Services</li>
          </ul>
        </div>
        <div className="col-md-3 fs-bold">
          <h4 className="main-color fs-bold">Community</h4>
          <ul className="text-primary">
            <li>Blog</li>
            <li>FAQ’s</li>
          </ul>
        </div>
        <div className="col-md-3">
          <h4 className="main-color fs-bold">Quick Links</h4>
          <ul className="text-primary">
            <li>
              <NavLink to="/">Overview</NavLink>
            </li>
            <li>
              <NavLink to="/sms-marketing">SMS Marketing</NavLink>
            </li>

            <li>
              <NavLink to="/contact">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/about">About Us</NavLink>
            </li>
          </ul>
        </div>
        <div className="text-center">
          <h5>
            <span style={{ color: "#7a7a7a" }}>
              © 2024 &#8211; All Rights Reserved EZ DIGITAL MARKETING LLP .
            </span>
          </h5>
        </div>
      </div>
    </>
  );
};

export default Footer;
