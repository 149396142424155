import contactUsImg from "./images/contacts.jpg";
import emailImg from "./images/em.png";
import phoneImg from "./images/phone.png";
import locationImg from "./images/location.png";
import Footer from "./Footer";

const Contact = () => {
  return (
    <>
      <section id="contactus">
        <div className="row text-center">
          <div className="col">
            <h2 className="main-color mt-3 fw-bold text-decoration-underline" data-aos="fade-up">
              Contact Us
            </h2>
            <h4 className="mt-2 fw-bold" data-aos="fade-down">
              Find the Perfect Solution for Your Business
            </h4>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-6" data-aos="fade-right">
            <img
              className="image-fuild animated"
              src={contactUsImg}
              alt="contact-brand"
              style={{ width: "100%" }}
              loading="lazy"
            />
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <h4 className="mt-3 fw-bold">GET IN TOUCH</h4>
            <h6 className="text-secondary">
              We have flexible support via live chat, email and phone. I
              Guarantee that you’ll be able to have any issue resolved within 24
              hours.
            </h6>
            <h4 className="mt-3 fw-bold">Phone No:</h4>
            <img
              src={phoneImg}
              alt="phone"
              style={{ width: "20px" }}
              loading="lazy"
            />
            <span className="p-1">+91 70088 36463</span>

            <h4 className="mt-3 fw-bold">Send Email:</h4>

            <img
              src={emailImg}
              alt="email"
              style={{ width: "20px" }}
              loading="lazy"
            />
            <span className="p-1">admin@ezdigital.in</span>
            <h4 className="mt-3 fw-bold fw-5">Address:</h4>
            <img
              src={locationImg}
              alt="location"
              style={{ width: "20px" }}
              loading="lazy"
            />
            <span className="text-secondary">
              Shop no.19, Ground floor, Radha Madhav Textile Market, Devadh,
              Surat, Gujarat, 394210
            </span>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Contact;
