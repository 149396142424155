import Footer from "./Footer";
import emailMarketing from "./images/emailMarketingD.png";

const EmailMarketing = () => {
  return (
    <section id="emailMarketing">
      <div className="row">
        <div className="col-md-12 text-center mt-3" data-aos="fade-up">
          <h2 className="main-color fw-bold text-decoration-underline">Email Marketing</h2>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6" data-aos="fade-right">
          <img
            src={emailMarketing}
            className="image-fuild animated"
            alt="email Marketing"
            style={{ width: "100%" }}
            loading="lazy"
          />
        </div>
        <div className="col-md-6" data-aos="fade-left">
          <div className="row">
            <div className="col-md-12">
              <h3 className="fw-bold">We Provide Best Email Marketing Services</h3>
            </div>
          </div>
          <div className="row">
              <div className="col-md-6">
                <h4 className="main-color fw-bold">01. Build Email Lists</h4>
                <p className="mt-2">
                We create lists of people who are interested in your business, like your customers and subscribers.
                </p>
              </div>
              <div className="col-md-6">
                <h4 className="main-color fw-bold">02. Personalization</h4>
                <p className="mt-2">
                We make emails feel special by using the recipient’s name and sending content that’s relevant to them.
                </p>
              </div>
              <div className="col-md-6">
                <h4 className="main-color fw-bold">03. Automation</h4>
                <p className="mt-2">
                We set up automated emails for things like welcoming new subscribers or sending birthday wishes.
                </p>
              </div>
              <div className="col-md-6">
                <h4 className="main-color fw-bold">04. Timing</h4>
                <p className="mt-2">
                We send emails at the right time so they’re more likely to be read and acted upon.
                </p>
              </div>

          </div>
        </div>
      </div>
    <Footer/>
    </section>
  );
};

export default EmailMarketing;
